<template>
  <div class="businessBox">
    <div class="businessWrap" v-for="(item, index) in dataList" :key="index">
      <div class="businessTitle clearfloat">
        <div class="fl">
          <h3><span></span> {{item.title}}</h3>
        </div>
        <div class="fr" v-if="item.url && item.url !==''">
          <a class="businessTitleLink" :href="item.url" target="_blank">访问主页</a>
        </div>
      </div>
      <div class="businessInner">
        <div class="pic">
          <img :src="item.img" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList:[
      ],
    }
  },
  mounted() {

  },
  methods: {
    
  }
}
</script>